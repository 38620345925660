import { useState, useEffect, useCallback } from 'react';
import { useSpring, animated } from 'react-spring';
import { BASE_URL, DEMO, scratchImg } from '../../utils/Constants';
import styles from './Redeem.module.css';

// import win from '../../assets/50euros.jpg';
// import ilo from '../../assets/ilo.png';
import ilo2 from '../../assets/gain.jpg';
// TODO: Convert fetch to axios
function Redeem() {
  const [isScratchCardDrawn, setIsScratchCardDrawn] = useState(false);
  const [prize, setPrize] = useState<null | { name: string, image: string }>(null);
  const [imageSrc, setImageSrc] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [isError, setIsError] = useState(false);

  const [isRedeem, setIsRedeem] = useState(false);

  const createScratchCard = useCallback((color: any) => {
    if (isScratchCardDrawn || isRedeem) return;

    let canvas = document.getElementById("scratch-card")! as any;
    let root = document.getElementById("root")! as any;
    let context = canvas.getContext("2d");

    // const init = () => {
    //   context.fillStyle = color;
    //   context.fillRect(0, 0, 300, 300);
    // };
    canvas.width *= 1.02;
    canvas.height *= 1.1;

    const init = () => {
      let imageObj = new Image();
      imageObj.onload = () => {
        context.drawImage(imageObj, 0, 0, canvas.width, canvas.height);
        setIsScratchCardDrawn(true);
      };
      imageObj.src = scratchImg;
    };

    let isDragging = false;
    let lastX: number | null = null;
    let lastY: number | null = null;

    const scratch = (x: any, y: any) => {
      context.globalCompositeOperation = "destination-out";
      context.lineJoin = "round";
      context.lineWidth = 30;

      if (lastX && lastY) {
        context.beginPath();
        context.moveTo(lastX, lastY);
        context.lineTo(x, y);
        context.closePath();
        context.stroke();
      }

      lastX = x;
      lastY = y;

      checkCompletion();
    };

    const checkCompletion = () => {
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let count = 0;

      for (let i = 0; i < data.length; i += 4) {
        if (data[i + 3] === 0) {
          count++;
        }
      }

      if (count / (data.length / 4) > 0.75 && !isRedeem) {
        setIsRedeem(true);
        // completed();
        canvas.style.display = 'none';
      }
    };

    canvas.addEventListener("mousedown", (event: any) => {
      isDragging = true;
      scratch(event.offsetX, event.offsetY);
    });

    root.addEventListener("mouseup", () => {
      isDragging = false;
      lastX = null;
      lastY = null;
    });

    root.addEventListener("mouseleave", () => {
      isDragging = false;
      lastX = null;
      lastY = null;
    });

    canvas.addEventListener("mousemove", (event: any) => {
      if (isDragging)
        scratch(event.offsetX, event.offsetY);
    });

    const getTouchPos = (canvas: any, touchEvent: any) => {
      var rect = canvas.getBoundingClientRect();
      var scaleX = canvas.width / rect.width;    // relationship bitmap vs. element for X
      var scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y

      return {
        x: (touchEvent.touches[0].clientX - rect.left) * scaleX,   // scale mouse coordinates after they have
        y: (touchEvent.touches[0].clientY - rect.top) * scaleY     // been adjusted to be relative to element
      };
    };

    root.addEventListener("touchstart", (event: any) => {
      isDragging = true;
      let touchPos = getTouchPos(canvas, event);
      scratch(touchPos.x, touchPos.y);
    });

    root.addEventListener("touchend", () => {
      isDragging = false;
      lastX = null;
      lastY = null;
    });

    root.addEventListener("touchcancel", () => {
      isDragging = false;
      lastX = null;
      lastY = null;
    });

    canvas.addEventListener("touchmove", (event: any) => {
      if (isDragging) {
        let touchPos = getTouchPos(canvas, event);
        scratch(touchPos.x, touchPos.y);
      }
    });

    init();
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (DEMO) {
      setPrize({ name: 'Une entrée gratuite pour un diner spectacle dansant', image: 'champagne.png' });
      // createScratchCard("#006d77");
      return;
    }

    fetch(`${BASE_URL}/api/redeem/prize/${code}`)
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          console.error(data.error);
          window.location.href = '/';
          return;
        }
        setPrize(data.prize)
        // createScratchCard("#006d77");
      })
      .catch(error => console.error(error));
  }, [createScratchCard]);

  // Send post to /redeem/code when compelte
  const completed = () => {
    if (DEMO) {
      setMessage("Bravo vous avez gagné " + prize?.name + ", merci de regardez vos mails");
      return;
    }

    fetch(`${BASE_URL}/api/redeem/redeem/${window.location.search.split('=')[1]}`, {
      method: 'GET',
    })
      .then(response => response.json())
      .then(data => {
        if (!data.success) {
          console.error(data.error);
          setMessage(data.error);
          setIsError(true);
          return;
        }

        setMessage("Bravo vous avez gagné " + prize?.name + ", merci de regardez vos mails");
      })
      .catch(error => console.error(error));
  };

  // useEffect(() => {
  //   if (prize && !isScratchCardDrawn) {
  //     createScratchCard("#006d77");
  //   }
  // }, [prize, createScratchCard]);

  useEffect(() => {
    if (prize && prize.image && !isScratchCardDrawn) {
      fetch(`${BASE_URL}/api/images/${prize.image}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const imageUrl = URL.createObjectURL(blob);
          setImageSrc(imageUrl);

          if (prize && !isScratchCardDrawn) {
            createScratchCard("#006d77");
          }
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }
  }, [prize]);

  useEffect(() => {
    if (isRedeem) {
      completed();
    }
  }, [isRedeem]);


  const messageAnimation = useSpring({
    opacity: message ? 1 : 0,
    transform: message ? 'translateY(0)' : 'translateY(100%)',
  });

  return (
    <div className={styles.wrapper}>
      {(prize &&
        <div className={styles.Redeem} id="redeem">
          <div className={styles.scratch}>
            {prize && isScratchCardDrawn && (
              <img className={styles.scratchImg} src={imageSrc} alt="prize" />
            )}
            {/* TODO: Put full opacity and fixed to the screen */}
            <animated.div className={styles.message} style={{ ...messageAnimation, textAlign: 'center', background: isError ? 'rgba(255, 0, 0, 0.7)' : 'rgba(0, 255, 0, 0.7)' }}>
              {message}
            </animated.div>
            <div className={`${styles.scratchcard} ${styles.noSelect}`}>
              <canvas id="scratch-card"></canvas>
            </div>
          </div>
          {/* TODO: Handle error */}
        </div>
      ) || (
          <div className={styles.loading}>
            <p>Chargement...</p>
          </div>
        )}
    </div>
  );
}

export default Redeem;