import axios from 'axios';
import { useMemo, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Box, Button, Checkbox, FormControlLabel, FormLabel, TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import bandeau from '../../assets/bandeau.png';
import { BASE_URL, RECAPTCHA_SITE_KEY } from '../../utils/Constants';
import styles from './Register.module.css';
import { DateField, DateValidationError } from '@mui/x-date-pickers';

dayjs.extend(utc);
dayjs.extend(timezone);

function isValidPhone(phone: string): boolean {
	const phoneWithoutSpaces = phone.replace(/\s/g, '');
	return /^(0|\+33)[1-9][0-9]{8}$/.test(phoneWithoutSpaces);
}

function isPostalCode(postalCode: string): boolean {
	return /^\d{5}$/.test(postalCode);
}

function Register() {
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [mail, setMail] = useState('');
	const [mailError, setMailError] = useState<string | null>(null);
	const [phone, setPhone] = useState('');
	const [phoneError, setPhoneError] = useState<string | null>(null);
	const [postalCode, setPostalCode] = useState('');
	const [postalCodeError, setPostalCodeError] = useState<string | null>(null);
	const [birthdate, setBirthdate] = useState<Dayjs | null>(null);
	const [birthdateErrorVisible, setBirthdateErrorVisible] = useState(false);
	const [birthdateError, setBirthdateError] = useState<DateValidationError | null>(null);
	const [gdprConsent, setGdprConsent] = useState(false);
	const [message, setMessage] = useState<string | null>(null);

	const captchaRef = useRef(null);

	const today = dayjs().tz('Europe/Paris');
	const minDate = today.subtract(100, 'year');
	const maxDate = today

	const [isError, setIsError] = useState(false);

	const birthdateErrorMessage = useMemo(() => {
		switch (birthdateError) {
			case 'maxDate': {
				return 'Merci de renseigner une date de naissance valide. Vous ne pouvez pas être né dans le futur.';
			}
			case 'minDate': {
				return 'Merci de renseigner une date de naissance valide';
			}

			case 'invalidDate': {
				return 'Date invalide';
			}

			default: {
				return '';
			}
		}
	}, [birthdateError]);

	const handleError = async (data: { error?: string, component?: string }) => {
		if (!data.component) {
			setMessage(data.error ?? "Une erreur est survenue lors de l'inscription. Veuillez réessayer.");
			return;
		}

		switch (data.component) {
			case "firstname": {
				setMessage(data.error!);
				break;
			}
			case "lastname": {
				setMessage(data.error!);
				break;
			}
			case "phone": {
				setPhoneError(data.error!);
				break;
			}
			case "mail": {
				setMailError(data.error!);
				break;
			}
			case "birthdate": {
				setBirthdateErrorVisible(true);
				setBirthdateError(data.error! as DateValidationError);
				break;
			}
			case "postalCode": {
				setPostalCodeError(data.error!);
				break;
			}
			default: {
				setMessage(data.error ?? "Une erreur est survenue lors de l'inscription. Veuillez réessayer.");
				break;
			}
		}
	};


	const handleSubmit = async (e: any) => {
		e.preventDefault();

		const token = (captchaRef.current as any)?.getValue();

		if (!isValidPhone(phone)) {
			setPhoneError("Numéro de téléphone invalide");
			return;
		}

		if (birthdateError) {
			setBirthdateErrorVisible(true);
			return;
		}

		if (!isPostalCode(postalCode)) {
			setPostalCodeError("Code postal invalide");
			return;
		}

		if (!token) {
			setMessage("Merci de résoudre la vérification.");
			setIsError(true);
			return;
		}

		const data = {
			firstname,
			lastname,
			mail,
			phone,
			postalCode,
			birthdate: birthdate?.toISOString().split('T')[0],
			token
		};

		try {
			const response = await axios.post(`${BASE_URL}/api/register/createUser`, data);
			if (!response?.data?.success) {
				if (!response.data) {
					console.error("No response data");
					setMessage("Une erreur est survenue lors de l'inscription. Veuillez réessayer.");
					setIsError(true);
					return;
				}

				console.error(response?.data?.error);
				(captchaRef.current as any)?.reset();

				handleError(response.data);
				setIsError(true);
				return;
			}

			console.log(response.data);
			setMessage(`Votre inscription a été prise en compte. Vous allez recevoir un ${response.data.component.toUpperCase()} avec un lien vers votre ticket.`);
			setIsError(false);
		} catch (error) {
			console.error(error);
			setMessage("Une erreur est survenue lors de l'inscription. Veuillez réessayer.");
			setIsError(true);
		}
		finally {
			(captchaRef.current as any)?.reset();
		}
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.register}>
				<div className="top">
				</div>
				<div className={styles.content}>
					<div className={styles.heading}>
						<img src={bandeau} alt="Header" />
						<h3>Grat-Online OFFERT par l’îlo café</h3>
						<h4>Inscris-toi et Grat’A Chance illico</h4>
						<h5>Cette année, l’îlo fête ces 20ans. Voici un 1er Jeu.
							<br />A GAGNER : 30 entrées pour les dîners spectacle dansant, 10 btes de Champagne, des forfaits boissons (conso-avec-modéra).
							<br />Valeur des Kdos: 1740€
						</h5>
					</div>
					{!isError && message ? (
						<p className={`${styles.message} ${styles.success}`}>{message}</p>
					) : (
						<Box
							component="form"
							sx={{
								'& .MuiTextField-root': { m: 0.5 },
							}}
							autoComplete="on"
							onSubmit={handleSubmit}
						>
							<TextField size="small" value={firstname} label="Prénom" onChange={(e) => { setFirstname(e.target.value); }} required />
							<TextField size="small" value={lastname} label="Nom" onChange={(e) => { setLastname(e.target.value); }} required />
							<TextField type="email" size="small" value={mail} label="Email" onChange={(e) => { setMailError(null); setMail(e.target.value); }} error={!!mailError} helperText={mailError} required />
							<TextField size="small" value={phone} label="Téléphone portable" onChange={(e) => { setPhoneError(null); setPhone(e.target.value); }} error={!!phoneError} helperText={phoneError} required
							/>
							<TextField type='number' size="small" value={postalCode} label="Code postal" onChange={(e) => { setPostalCodeError(null); setPostalCode(e.target.value); }} error={!!postalCodeError} helperText={postalCodeError} required />
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<DateField minDate={minDate} maxDate={maxDate} format="DD/MM/YYYY" timezone='Europe/Paris' size="small" value={birthdate} label="Date de naissance (Jour/Mois/Année)" onChange={(e) => { setBirthdateErrorVisible(false); setBirthdate(e) }} onError={(newError) => setBirthdateError(newError)} slotProps={{ textField: { required: true, helperText: birthdateErrorVisible && birthdateErrorMessage } }} />
							</LocalizationProvider>
							<FormControlLabel sx={{ 'marginLeft': "0px" }} required
								control={
									<Checkbox size="small" id="gdprConsent" aria-describedby="politique-de-confidentialité" value={gdprConsent} onChange={(e) => setGdprConsent(e.target.checked)} />
								}
								label={
									<FormLabel sx={{ fontSize: "12px" }} htmlFor="gdprConsent" >
										J'ai lu et j'accepte la <a href="/confidentialite" target="_blank" rel="noreferrer">politique de confidentialité.</a>
									</FormLabel>
								}
							/>
							<div className={styles.captcha}>
								<ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} ref={captchaRef} />
							</div>
							<Button size="small" type="submit" variant="contained">S'inscrire</Button>
						</Box>
					)}
					{message && isError && <p className={`${styles.message} ${styles.error}`}>{message}</p>}
					<p className={styles.disclaimer}>Vous allez recevoir le lien de votre “Grat-Online” par SMS, renseigner bien votre numéro de téléphone. Si vous êtes gagnant, vous recevrez la notification de votre gain par mail. En vous inscrivant, vous acceptez de recevoir des infos sur nos évènements.
					</p>
				</div>
			</div>
		</div>
	);
}

export default Register;