import React, { useEffect, useState } from 'react';

import { AppBar, Avatar, Button, Grid, List, ListItem, ListItemText, Paper, Tab, Tabs, Toolbar, Typography } from '@mui/material';
import { TableComponent } from '../../components/AdminTable';
import styles from './Admin.module.css';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { BASE_URL, REACT_APP_GOOGLE_CLIENT_ID } from '../../utils/Constants';
import axios from 'axios';

export interface IUser {
	id: number;
	firstname: string;
	lastname: string;
	phone: string;
	email: string;
	birthdate: string;
	postalCode: string;
	prize?: { id: number, prize: string, image: string };
}

export interface IPrize {
	id: number;
	prize: string;
	image: string;
	user?: { id: number, firstname: string, lastname: string };
}

export interface IData {
	users: IUser[];
	prizes: IPrize[];
	wonPrizes: IPrize[];
}

const fields = {
	user: [
		{ id: 1, label: 'First Name', key: 'firstname' },
		{ id: 2, label: 'Last Name', key: 'lastname' },
		{ id: 3, label: 'Phone', key: 'phone' },
		{ id: 4, label: 'Email', key: 'mail' },
		{ id: 5, label: 'Birthdate', key: 'birthdate' },
		{ id: 6, label: 'Postal Code', key: 'postalCode' },
	],
	prize: [
		{ id: 1, label: 'Prize', key: 'prize' },
		{ id: 2, label: 'Image', key: 'image' },
		{ id: 3, label: 'User', key: 'user' },
	],
	wonPrize: [
		{ id: 1, label: 'Prize', key: 'prize' },
		{ id: 2, label: 'Image', key: 'image' },
		{ id: 3, label: 'User', key: 'user' },
	],
}

const AdminPage = () => {
	const [selectedTab, setSelectedTab] = useState(0);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const [cachedData, setCachedData] = useState<IData>({ users: [], prizes: [], wonPrizes: [] });
	const [total, setTotal] = useState({ users: 0, prizes: 0, wonPrizes: 0 });

	const [focusVisible, setFocusVisible] = useState(false);
	const [focusData, setFocusData] = useState<IUser | null>(null);

	const [authData, setAuthData] = useState<any>(null);

	const getFocusInfo = async (item: any) => {
		// Make API call
		// const response = await fetch(`/api/path/${item.id}`);
		// const data = await response.json();

		// Set focus data and make it visible
		console.log(item)
		if (!item.user) return;

		const usr = item.user;

		setFocusData({
			id: 1,
			firstname: usr.firstname,
			lastname: usr.lastname,
			phone: usr.phone,
			email: usr.mail,
			postalCode: usr.postalCode,
			birthdate: usr.birthdate,
			prize: { id: item.id, prize: 'Prize 1', image: 'image1' }
		});
		setFocusVisible(true);
	};

	const handleSignOut = () => {
		// Clear access token and redirect to /
		localStorage.removeItem('authToken');
		setAuthData(null);
		// window.location.href = '/';
	};

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setPage(0);
		setSelectedTab(newValue);
	};

	useEffect(() => {
		const fetchData = async () => {
			// Get local storage data
			const authToken = localStorage.getItem('authToken');
			if (authToken) {
				try {
					const { data } = await axios.get(`${BASE_URL}/api/admin/me`, { headers: { Authorization: `${authToken}` } });
					if (!data || !data.success || !data.data) {
						localStorage.removeItem('authToken');
						console.error(data?.error);
						return;
					}

					setAuthData(data.data);
				}
				catch (error) {
					console.error(error);
					// localStorage.removeItem('authToken');
				}
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			// Get local storage data
			const authToken = localStorage.getItem('authToken');
			if (authToken) {
				try {
					let endpoint = '';
					switch (selectedTab) {
						case 0:
							endpoint = 'users';
							break;
						case 1:
							endpoint = 'prizes';
							break;
						case 2:
							endpoint = 'wonPrizes';
							break;
						default:
							return;
					}

					const { data } = await axios.get(`${BASE_URL}/api/admin/${endpoint}?page=${page}&rowsPerPage=${rowsPerPage}`, { headers: { Authorization: `${authToken}` } });
					if (!data || !data.success || !data.data) {
						console.error(data?.error);
						return;
					}

					setCachedData(prevData => ({ ...prevData, [endpoint]: data.data }));
					setTotal(prevTotal => ({ ...prevTotal, [endpoint]: data.total }));
				}
				catch (error) {
					console.error(error);
				}
			}
		};

		fetchData();
	}, [authData, page, rowsPerPage, selectedTab]);

	return (
		<div>
			{!authData ?
				<div className={styles.loginContainer} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
					<h1>Page d'administration, cliquez pour se connecter</h1>
					<div style={{ width: '300px', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px solid #ccc', borderRadius: '5px' }}>
						<GoogleOAuthProvider clientId={REACT_APP_GOOGLE_CLIENT_ID}>
							<GoogleLogin
								useOneTap={true}
								onSuccess={async (credentialResponse) => {
									const { data } = await axios.post(`${BASE_URL}/api/auth/login`, { token: credentialResponse.credential });
									if (!data || !data.success || !data.data) {
										console.error(data?.error);
										window.location.href = '/';
										return;
									}
									// Save data to local storage
									localStorage.setItem('authToken', data.data.token);
									setAuthData({ nickname: data.data.nickname, image: data.data.image });
								}}
								onError={() => {
									console.log("Login Failed");
								}}
							/>
						</GoogleOAuthProvider>
					</div>
				</div>
				:
				<div>
					<AppBar position="static">
						<Toolbar>
							<Typography variant="h6" style={{ flexGrow: 1 }}>
								Admin Panel
							</Typography>
							{authData && <Avatar alt={authData.nickname} src={authData.image} style={{ marginRight: 15 }} />}
							<Typography variant="h6" style={{ marginRight: 15 }}>
								{authData.nickname}
							</Typography>
							<Button variant="contained" color="error" onClick={handleSignOut}>Sign Out</Button>
						</Toolbar>
					</AppBar>
					<Grid container spacing={2} sx={{ 'padding': "2vh" }}>
						<Grid item xs={12}>
							<Tabs centered value={selectedTab} onChange={handleChange}>
								<Tab label="Users" />
								<Tab label="Prizes" />
								<Tab label="Won Prizes" />
							</Tabs>
						</Grid>
						<Grid item xs={(focusVisible && focusData) ? 10 : 12}>
							{cachedData && selectedTab === 0 && <TableComponent fields={fields.user} data={cachedData.users} total={total.users} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} getFocusInfo={getFocusInfo} />}
							{cachedData && selectedTab === 1 && <TableComponent fields={fields.prize} data={cachedData.prizes} total={total.prizes} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} getFocusInfo={getFocusInfo} />}
							{cachedData && selectedTab === 2 && <TableComponent fields={fields.wonPrize} data={cachedData.wonPrizes} total={total.wonPrizes} page={page} rowsPerPage={rowsPerPage} setPage={setPage} setRowsPerPage={setRowsPerPage} getFocusInfo={getFocusInfo} />}

						</Grid>
						{focusVisible && focusData && (
							<Grid item xs={2}>
								<Paper>
									<List>
										<ListItem>
											<ListItemText primary="First Name" secondary={focusData.firstname} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Last Name" secondary={focusData.lastname} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Phone" secondary={focusData.phone} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Email" secondary={focusData.email} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Postal Code" secondary={focusData.postalCode} />
										</ListItem>
										<ListItem>
											<ListItemText primary="Birthdate" secondary={focusData.birthdate} />
										</ListItem>
										<ListItem>
											<Button variant="contained" color="error" onClick={() => setFocusVisible(false)}>Fermer</Button>
										</ListItem>
									</List>
								</Paper>
							</Grid>
						)}
					</Grid>
				</div>
			}
		</div>
	);
};

export default AdminPage;