import React from 'react';
import styles from './Confidentialite.module.css';

function Confidentialite() {
	return (
		<div className={styles.wrapper}>
			<div className={styles.confidentialite}>
				<div className={styles.center}>
					<h1>Politique de confidentialité</h1>
					<p>Conformément à l'article 13 du règlement UE no. 679/2016 défini comme "Règlement général sur la protection des données" et art. 13 du décret législatif no. 196-2003 défini "Code de confidentialité", ÎLO CAFE (ci-après «Titulaire du traitement») informent que le traitement des données personnelles fournies via le formulaire géré par “Grat-Online”, gestionnaire du service relatif à la demande spécifique que vous avez formulée (ci-après «Responsable du traitement»), sera traité comme suit.</p>
				</div>

				<h3>1. Objectif</h3>
				<p>1.1 Avec votre consentement écrit spécifique, les coordonnées fournies lors de l'inscription seront utilisées pour envoyer des communications périodiques par e-mail ou SMS à caractère informatif.</p>

				<h3>2. Données collectées. Nécessité</h3>
				<p>2.1 La communication des données personnelles marquées d'un astérisque est nécessaire. Leur communication et leur traitement sont obligatoires, et à défaut, il ne sera pas possible de donner suite à la demande formulée via les formulaires de contact et / ou d'inscription.</p>
				<p>2.2 La communication des données non marquées d'un astérisque est facultative, et leur non-communication empêchera, en tout ou en partie, l'envoi de communications personnalisées.</p>

				<h3>3. Méthodes de traitement et temps de stockage**</h3>
				<p>3.1 Le traitement des données à caractère personnel pour chacune des finalités susmentionnées est effectué par des moyens papier, automatisés ou électroniques et, notamment, par courrier ordinaire ou e-mail, téléphone (par exemple appels téléphoniques, SMS, MMS) et tout autre canal informatique (par exemple, sites Web, mobiles, applications) aptes à garantir la sécurité et la confidentialité selon ce que l'on appelle la protection des données par défaut, c'est-à-dire l'application de mesures visant à minimiser les risques de diffusion des données.</p>
				<p>3.2 Les données fournies seront traitées conformément à la règle susmentionnée, répondant aux obligations de confidentialité et de licéité qui inspirent notre société et pour le temps strictement nécessaire à l'exécution des services pour lesquels elles ont été collectées. De plus, les données ne seront pas diffusées.</p>
				<p>3.3 Les données fournies seront en tout état de cause traitées pendant une période n'excédant pas vingt-quatre mois à des fins de marketing direct.</p>

				<h3>4. Limites subjectives</h3>
				<p>L'inscription aux services du site est réservée aux personnes physiques ayant atteint l'âge de 20 ans révolus.</p>

				<h3>5. Consentement</h3>
				<p>5.1 Le consentement n'est pas requis lors du traitement:</p>
				<ol type="a">
					<li>Il est nécessaire de remplir une obligation établie par la loi, par un règlement ou par la législation communautaire;</li>
					<li>Il est nécessaire d'exécuter des obligations découlant d'un contrat auquel l'intéressé est partie ou de satisfaire, avant la conclusion du contrat, des demandes spécifiques de l'intéressé;</li>
					<li>Concerne des données provenant de registres publics, de listes, d'actes ou de documents qui peuvent être connus de tous, sans préjudice des limites et des procédures établies par les lois, règlements ou législation communautaire pour la divulgation et la publicité des données.</li>
				</ol>
				<p>5.2 Le consentement à l'envoi de communications de marketing direct peut être révoqué à tout moment en suivant le lien hypertexte (lien) affiché au bas de chaque message électronique promotionnel.</p>

				<h3>6. Communication de données. Transfert</h3>
				<p>Les données personnelles fournies lors de l'inscription sur le site ne seront pas diffusées. Pour des raisons strictement fonctionnelles à l'exécution de la demande, elles seront communiquées à:</p>
				<ul>
					<li>Les employés et/ou collaborateurs internes dûment nommés et autorisés;</li>
					<li>Des collaborateurs externes et des responsables externes dans tous les cas identifiés, désignés et en charge de cette activité.</li>
				</ul>

				<h3>7. Droits de l’intéressé</h3>
				<p>Nous vous rappelons que les droits suivants sont reconnus:</p>
				<ol type="1">
					<li>Le droit d'accès, c'est-à-dire le droit d'obtenir des responsables du traitement la confirmation que les données sont traitées ou non et, dans ce cas, d'y avoir accès;</li>

					<li>Le droit de rectification et d'annulation, c'est-à-dire le droit d'obtenir la correction de données inexactes et/ou l'intégration de données incomplètes ou l'annulation de données pour des motifs légitimes;</li>

					<li>Le droit de limiter le traitement, c'est-à-dire le droit de demander la suspension du traitement s'il existe des motifs légitimes;</li>

					<li>Le droit à la portabilité des données, c'est-à-dire le droit de recevoir les données dans un format structuré, couramment utilisé et lisible, ainsi que le droit de transmettre les données à un autre responsable du traitement;</li>

					<li>Le droit de retirer votre consentement à tout moment. Le retrait du consentement n'affecte pas la licéité du traitement fondé sur le consentement avant le retrait.</li>

					<li>Le droit d'opposition, c'est-à-dire le droit de s'opposer au traitement des données s'il existe des motifs légitimes, y compris le traitement des données à des fins de marketing et de profilage, le cas échéant;</li>

					<li>Le droit de porter plainte auprès de l'autorité de contrôle compétente en cas de traitement illicite des données ou d'exercer les fonctions judiciaires appropriées.</li>
				</ol>
				<p>Les demandes devront être envoyées à ÎLO CAFE à l'adresse e-mail suivante: <a href="mailto:contact@ilocafe.com">contact@ilocafe.com</a></p>

				<h3>8. Titulaire et responsables du traitement</h3>
				<p>Le Titulaire du traitement est ÎLO CAFE, 275 Route de Lyon - 69610 Saint Genis l’Argentière.</p>
				<p>Le responsable du traitement est Grat-Online, 785 Route de Lyon - 69610 Saint Genis l’Argentière.</p>
			</div>
		</div>
	);
}

export default Confidentialite;