import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate, useParams } from 'react-router-dom';

import { createGlobalStyle } from 'styled-components'

import './index.css';

import Register from './pages/Register';
import Redeem from './pages/Redeem';
import Confidentialite from './pages/Confidentialite';
import AdminPage from './pages/Admin';
// import Home from './pages/Home';

const GlobalStyle = createGlobalStyle`
    div {
        font-family: 'Trebuchet MS', Helvetica, sans-serif;
    }
`

const RedirectWithCode = () => {
  let { code } = useParams();
  return <Navigate to={`/redeem?code=${code}`} replace />;
};

function App() {

  return (
    <React.StrictMode>
      <Router>
        <GlobalStyle />
        <div className="App">
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/" element={<Register />} />
            <Route path="/redeem" element={<Redeem />} />
            <Route path="/confidentialite" element={<Confidentialite />} />
            <Route path="/admin" element={<AdminPage />} />
            {/* <Route path="/register" element={<Register />} /> */}
            <Route path=":code" element={<RedirectWithCode />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Router>
    </React.StrictMode>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));