import React from 'react';

import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { IPrize, IUser } from '../../pages/Admin';
import styles from './AdminTable.module.css';

interface ITableComponentProps {
	fields: { id: number, label: string, key: string }[];
	data: IUser[] | IPrize[];
	total: number;
	page: number;
	rowsPerPage: number;
	setPage: (page: number) => void;
	setRowsPerPage: (rowsPerPage: number) => void;
	getFocusInfo: (item: IUser | IPrize) => void;
}

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (
		event: React.MouseEvent<HTMLButtonElement>,
		newPage: number,
	) => void;
}


export const TableComponent: React.FC<ITableComponentProps> = ({ fields, data, total, page, rowsPerPage, setPage, setRowsPerPage, getFocusInfo }) => {

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	function TablePaginationActions(props: TablePaginationActionsProps) {
		const { count, page, rowsPerPage, onPageChange } = props;

		const handleFirstPageButtonClick = (
			event: React.MouseEvent<HTMLButtonElement>,
		) => {
			onPageChange(event, 0);
		};

		const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page - 1);
		};

		const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, page + 1);
		};

		const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
			onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
		};
		return (
			<Box sx={{ flexShrink: 0, ml: 2.5 }}>
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
				>
					<FirstPage />
				</IconButton>
				<IconButton
					onClick={handleBackButtonClick}
					disabled={page === 0}
					aria-label="previous page"
				>
					<KeyboardArrowLeft />
				</IconButton>
				<IconButton
					onClick={handleNextButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="next page"
				>
					<KeyboardArrowRight />
				</IconButton>
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
				>
					<LastPage />
				</IconButton>
			</Box>
		);
	}

	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						{fields.map((field) => (
							<TableCell key={field.id}>{field.label}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{
						data.map((item) => (
							<TableRow key={item.id}>
								{fields.map((field) => (
									<TableCell key={field.id} onClick={typeof (item as any)[field.key] === 'object' ? () => getFocusInfo(item) : undefined} className={typeof (item as any)[field.key] === 'object' && (item as any)[field.key]?.firstname ? styles.link : undefined}>
										{typeof (item as any)[field.key] === 'object' && (item as any)[field.key]?.firstname
											? `${(item as any)[field.key].firstname} ${(item as any)[field.key].lastname}`
											: (item as any)[field.key]}
									</TableCell>)
								)}
							</TableRow>
						))
					}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePagination
							rowsPerPageOptions={[10, 25, 50]}
							colSpan={7}
							count={total}
							rowsPerPage={rowsPerPage}
							page={page}
							slotProps={{
								select: {
									inputProps: {
										'aria-label': 'rows per page',
									},
									native: true,
								},
							}}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							ActionsComponent={TablePaginationActions}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	);
};